import React from "react"
import PropTypes from "prop-types"
import { withStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import ProductCurvyLines from "../assets/images/productCurvyLines.png"

// import Spacer from "./UI/SectionSpacer"

import { GiRiver } from "react-icons/gi"
import { FaHammer } from "react-icons/fa"

const styles = theme => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 5),
    },
  },
  image: {
    height: 55,
    verticalAlign: "bottom",
    color: theme.palette.secondary.main,
    "& svg": {
      fill: theme.palette.secondary.main,
      stroke: theme.palette.secondary.main,
      "& path": {
        fill: theme.palette.secondary.main,
        stroke: theme.palette.secondary.main,
      },
    },
  },
  title: {
    fontSize: "1.25rem",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  descrip: {
    fontSize: "1rem",
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
})

function ConsultationSection(props) {
  const { classes } = props
  const theme = useTheme()

  return (
    <>
      <section className={classes.root}>
        <Container className={classes.container}>
          <img
            src={ProductCurvyLines}
            className={classes.curvyLines}
            alt="curvy lines"
          />
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <GiRiver size={55} className={classes.image} />
                <Typography variant="h6" className={classes.title}>
                  New Water Features
                </Typography>
                <Typography variant="h5" className={classes.descrip}>
                  {
                    "Thinking about having a new pond or waterfall installed? Ready to remodel your existing feature?"
                  }
                  <br />
                  <br />
                  {
                    "Tonja will come to your site to discuss what your future water feature will consist of and provide you with an estimate for what the job will cost for a $65 design consultation fee."
                  }
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <FaHammer size={55} className={classes.image} />
                <Typography variant="h6" className={classes.title}>
                  DIY Consultations
                </Typography>
                <Typography variant="h5" className={classes.descrip}>
                  {
                    "If you've decided to do the work yourself but are having trouble along the way, we can come to your site and help with everything from design ideas to troubleshooting problems."
                  }
                  <br />
                  <br />
                  {
                    "This consultation is $65 per hour and we can come out just once or as many times as you need assistance.  We can also provide supplies for your project such as liner, pumps, skimmers, etc."
                  }
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="55"
                  width="55"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke={theme.palette.secondary.main}
                    strokeWidth="2"
                    d="M1,5 C1,3.00000024 2,1 3,1 C3,1 5,5 5,5 L7,5 C7,5 9,1 9,1 C10,1 11,3.00000006 11,5 C11,7.25400025 10,9.0000003 8,10 L8,21 C8,22 8,23 6,23 C4,23 4,22 4,21 L4,10 C2,9.0000003 1,7.25400042 1,5 Z M19,12 L19,18 M17,18 L18,23 L20,23 L21,18 L17,18 Z M14,12 L24,12 L14,12 Z M21,12 L21,3 C21,1.895 20.105,1 19,1 C17.895,1 17,1.895 17,3 L17,12"
                  ></path>
                </svg>
                <Typography
                  variant="h6"
                  align="center"
                  className={classes.title}
                >
                  Troubleshooting Consultations
                </Typography>
                <Typography variant="h5" className={classes.descrip}>
                  {
                    "Whatever the problem, we can come out and troubleshoot it to help you get it resolved.  A troubleshooting house-call is $65 plus materials costs."
                  }
                  <br />
                  <br />
                  {
                    "One of our maintenance technicians will come investigate the problem you are having with your pond, waterfall or hardscape. They will complete the repair or give you an estimate for a repair cost."
                  }
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      {/* <Spacer /> */}
    </>
  )
}

ConsultationSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ConsultationSection)
