import React from "react"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import ConsultationDescription from "../components/ConsultationDescrip"
import ConsultationSection from "../components/ConsultationSections"
import CallToAction from "../components/CallToAction"

export default function ConsultationPage() {
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Consultation Services"
        description="Learn about professional consultations for ponds and other water features by Andreatta Waterscapes."
      />
      <ConsultationDescription />
      <ConsultationSection />
      <CallToAction />
    </Layout>
  )
}
