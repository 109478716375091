import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
  body: {
    padding: "6.25em 0",
  },
  card: {
    width: "65%",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  hr: {
    width: "3em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".125rem",
    borderColor: theme.palette.common.blue,
  },
  sectionTitle: {
    ...theme.typography.sectionHeading,
  },
  h3: {
    color: theme.palette.secondary.main,
    letterSpacing: "-.2px",
    textTransform: "uppercase",
    fontSize: "2.625em",
    fontWeight: 500,
    marginTop: "-2rem",
    paddingBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingTop: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.25em",
    },
  },
}))

export default function ConsultationDescrip() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.body}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: "1.9875em" }}
          >
            <Grid item md={12} lg={6}>
              <Paper className={classes.card} elevation={10}>
                <StaticImage
                  src="../assets/images/Andreatta_Waterscapes_pond-consultation-2_Central-Point_Oregon.jpg"
                  height={500}
                  quality={95}
                  layout="constrained"
                  alt="Andreatta Waterscapes at Work"
                  placeholder="blurred"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                  imgStyle={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 2,
                  }}
                />
              </Paper>
            </Grid>
            <Grid
              item
              md={12}
              lg={6}
              style={{
                paddingTop: matchesMD ? "2em" : undefined,
                paddingLeft: matchesMD ? undefined : "4em",
              }}
            >
              <Grid
                container
                direction="row"
                justify={matchesMD ? "center" : undefined}
              >
                <Grid
                  item
                  xs={10}
                  sm={11}
                  md={9}
                  lg={10}
                  xl={8}
                  style={{ paddingTop: ".2em" }}
                >
                  <Grid container justify={matchesMD ? "center" : undefined}>
                    <Grid item xs={10} sm={11} md={10} lg={9}>
                      <Typography
                        variant="h1"
                        color="secondary"
                        align={matchesMD ? "center" : undefined}
                        className={classes.h3}
                        gutterBottom
                      >
                        Consultations
                      </Typography>

                      <Typography
                        variant="body1"
                        align={matchesMD ? "center" : undefined}
                        gutterBottom
                      >
                        <span style={{ color: theme.palette.secondary.main }}>
                          Consultations For Your Project Or Repair
                        </span>
                        <br />
                        <br />
                        If you've been thinking about having a water feature
                        installed or remodeled, you're a Do-it-yourselfer who
                        has run into problems, or you've got a problem with your
                        current water feature, we can help.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
